import '@fortawesome/fontawesome-svg-core/styles.css'
import { config, library } from '@fortawesome/fontawesome-svg-core'
import {
  faAmazon,
  faApple,
  faDeezer,
  faInstagram,
  faSpotify,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'

config.autoAddCss = false
library.add(faInstagram, faApple, faDeezer, faAmazon, faSpotify, faYoutube)
