import { extendTheme, theme as baseTheme } from '@chakra-ui/react'
import { ChakraTheme } from '@chakra-ui/theme'
import { ThemeOverride } from '@chakra-ui/react'

export const themeExtension: ThemeOverride<ChakraTheme> = {
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  colors: {
    brand: {
      100: '#0F0F11',
      // 900: '#0F0F11',
    },
  },
  fonts: {
    heading: `'Rakkas', serif`,
    body: `'Lato', sans-serif`,
  },
  styles: {
    global: {
      'html, body, #___gatsby, #gatsby-focus-wrapper': {
        // height: '100%',
        margin: 0,
        overflowX: 'hidden',
        backgroundColor: '#0F0F11',
      },
    },
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 'normal',
      },
    },
  },
}

export const retroTheme = extendTheme(
  themeExtension,
  baseTheme as ChakraTheme,
) as ChakraTheme
